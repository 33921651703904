import React from "react";

// reactstrap components

// core components

import contactHeaderImg from "assets/img/contact-bannerNew.jpg"

import {
  Row,
  Col
} from "reactstrap";

// import AOS from "aos"
// import "aos/dist/aos.css"

function ContactUsHeader() {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            background:"linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.6)),"+ "url(" + contactHeaderImg + ")"
          ,backgroundSize:'cover',
          backgroundPosition:'center'
          }}
          ref={pageHeader}
        ></div>
              <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 data-aos="fade-left" data-aos-duration="2800" style={{fontFamily:"Quintessential"}} className="title">Contact Us</h1>
              <h4 data-aos="fade-right" style={{fontFamily:"Quintessential"}} data-aos-duration="2800">
                Feel free to contact us if you have any queries. We'll be happy to help!
              </h4>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ContactUsHeader;
