import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css"
// core components

function Features() {
  React.useEffect(()=>{
    AOS.init({duration:2800});
  },[])
  return (
    <>
      <div className="cd-section" id="features">
        <div className="features-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title" style={{color:"black",fontFamily:"Quintessential"}}>Simpler. Smarter. Faster.</h2>
                <h4  data-aos="fade-right" className="description"  style={{fontWeight:400,color:"black"}}>
                MahaGauri Enterprises Pvt. Ltd., 
                has emerged as a major export house for a large variety of food products
                 because of its innovative and focused approach world wide by following 
                 strategic policies and by processing through modern technologies.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4" sm="6" xs="6">
                <div className="info info-hover">
                  <div className="icon icon-info icon-circle">
                   <i className="now-ui-icons shopping_box"></i>
                  </div>
                  <h4 className="info-title">Packaging</h4>
                  <p data-aos="fade-in" className="description featuresList"  style={{fontWeight:400}}>
                  All products are packaged with proper 
                  labeling and ensured that the products reach you safely.
                  </p>
                </div>
              </Col>
              <Col md="4" sm="6" xs="6">
                <div className="info info-hover">
                  <div className="icon icon-success icon-circle">
                    <i className="now-ui-icons emoticons_satisfied"></i>
                  </div>
                  <h4 className="info-title">Customer Satisfaction </h4>
                  <p data-aos="fade-in" className="description featuresList" style={{fontWeight:400}}>
                  We put extreme emphasis on the satisfaction of our customers with regards to our products and services.
                  </p>
                </div>
              </Col>
              <Col md="4" sm="6" xs="6">
                <div className="info info-hover">
                  <div className="icon icon-warning icon-circle">
                    <i className="now-ui-icons objects_diamond"></i>
                  </div>
                  <h4 className="info-title">Quality Policy</h4>
                  <p data-aos="fade-in" className="description featuresList" style={{fontWeight:400}}>
                  We are committed to quality of our products from start to the end.
                  </p>
                </div>
              </Col>
              <Col md="4" sm="6" xs="6">
                <div className="info info-hover">
                  <div className="icon icon-info icon-circle">
                     <i className="now-ui-icons design_app"></i>
                  </div>
                  <h4 className="info-title">Warehousing </h4>
                  <p data-aos="fade-in" className="description featuresList" style={{fontWeight:400}}>
                  We have well-organized warehouse unit that helps us to store our bulk quantity of safely and effectively.
                  </p>
                </div>
              </Col>              <Col md="4" sm="6" xs="6">
                <div className="info info-hover">
                  <div className="icon icon-success icon-circle">
                    <i className="now-ui-icons ui-1_send"></i>
                  </div>
                  <h4 className="info-title">Quick Support</h4>
                  <p data-aos="fade-in" className="description featuresList" style={{fontWeight:400}}>
                  Your queries are just a phone call away. We make sure to never make our clients wait.
                  </p>
                </div>
              </Col>

              <Col md="4" sm="6" xs="6">
                <div className="info info-hover">
                  <div className="icon icon-warning icon-circle">
                    <i className="now-ui-icons objects_globe"></i>
                  </div>
                  <h4 className="info-title">Year Round Supply </h4>
                  <p data-aos="fade-in" className="description featuresList" style={{fontWeight:400}}>
                  Exported produce available in all seasons with the highest quality possilbe.</p>
                </div>
              </Col>    
            </Row>
          </Container>
        </div>
       
      </div>
    </>
  );
}

export default Features;
