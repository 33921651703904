import React from "react";
import "aos/dist/aos.css";
// reactstrap components
import { Button, Container,Row,Col } from "reactstrap";

import landingHeaderImg from "assets/img/mge2.jpg"

import AOS from "aos";
// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(()=>{
    AOS.init({duration:2000});
  },[]);


  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <React.Fragment>
      <div className="page-header">
        <div
          className="page-header-image"
          style={{
            background:"linear-gradient(rgba(0,0,0,0.1),rgba(255,255,255,0.1)),"+ "url(" + landingHeaderImg + ")"
          ,backgroundSize:'cover',
          backgroundPosition:'center'
          }}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Container>
          <Row>
        <Col className="ml-auto mr-auto text-center" md="12">
          <h1 data-aos="fade-up" className="title mainHeading" style={{fontFamily:"Quintessential"}}>Welcome to Maha Gauri Enterprises</h1>
          <h3 className="front-quote" data-aos="fade-up" className="title" style={{fontWeight:10,fontFamily:"Satisfy",fontSize:"30px"}}>  From all Around the world
          <br/>
          Serving better than the best! 
          </h3>
          <br></br>
          <h5 data-aos="zoom-in-down" data-aos-anchor-placement="top-bottom">Connect with us on:</h5>
          <div className="buttons">
          <Button className="btn-icon btn-round mt-2 mr-1" color="twitter">
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button className="btn-icon btn-round mt-2 mr-1" color="facebook">
                  <i className="fab fa-facebook"></i>
                </Button><Button className="btn-icon btn-round mt-2 mr-1" color="google">
                  <i className="fab fa-google-plus"></i>
                </Button>
                <Button className="btn-icon btn-round mt-2 mr-1" color="linkedin">
                  <i className="fab fa-linkedin"></i>
                </Button>
                
            {/* <Button
              className="btn-icon btn-neutral btn-round mt-2 mr-1"
              color="danger"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <i className="fab fa-facebook-square"></i>
            </Button>
            <Button
              className="btn-icon btn-neutral btn-round mt-2 mr-1"
              color="danger"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <i className="fab fa-google-plus"></i>
            </Button>
            <Button
              className="btn-icon btn-neutral btn-round mt-2"
              color="danger"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <i className="fab fa-instagram"></i>
            </Button> */}
          </div>
        </Col>
      </Row>          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LandingPageHeader;
