import React, { useState } from "react";
// react plugin used to create google maps
import AOS from "aos"
import "aos/dist/aos.css"
import axios from 'axios'
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import MyNav from '../components/Navbars/FixedTransparentNavbar';
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import Footer from '../components/Footers/FooterDefault';
import Rellax from "rellax";
function ContactUs() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);
  React.useEffect(() => {
    AOS.init();

    if (window.innerWidth >= 801) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: false
        });
      }, 100);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }
    // function resetForm() {
    //   setName('')
    //   setEmail('')
    //   setMessage('')
    //   setNumber('')
    //   setButtonText('Message Sent')
    // }
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [buttonText, setButtonText] = useState('Send Message')
  const [sent, setSent] = useState(false);

  function formSubmit(e){
    e.preventDefault()

    setButtonText('...sending')

    let data = {
      name: name,
      email: email,
      message: message,
      number: number
    }
    console.log(data)
    axios.post('https://us-central1-emailserver-f66c4.cloudfunctions.net/app/api/v1', data)
      .then(res => {
        setSent(true)
        setName('')
        setEmail('')
        setMessage('')
        setNumber('')
        console.log("*******************************************************************")
        console.log(res)
        setButtonText('Message Sent!')
      })
      .catch((error) => {
        setName('')
        setEmail('')
        setMessage('')
        setNumber('')
        console.log(error)
        setButtonText('Error Sending Message...')
        console.log('Error Sending Message...')
      })
  }


  return (
    <React.Fragment>
      <MyNav activelink="Contact Us" />
      <ContactUsHeader />

      <div className='bg-elements ' style={{ height: 0, width: '100%', marginTop: "10vh", position: 'absolute', zIndex: '-1000' }}>
        <div className="bg-elements-2 rellax mr-5 ml-auto" data-rellax-speed='-2' style={{ minHeight: '150px', maxWidth: ' 150px', borderRadius: '20%', backgroundColor: 'rgba(255, 165, 0, 0.3)', transform: 'rotate(45deg)' }}></div>
        <div className="bg-elements-3 rellax  mr-auto" data-rellax-speed='-4' style={{ zIndex: '-1000', marginLeft: '10vw', minHeight: '50px', maxWidth: '50px', borderRadius: '20%', backgroundColor: 'rgba(255, 165, 0, 0.3)', transform: 'rotate(45deg)' }}></div>
        <div className="bg-elements-4 rellax  mr-auto" data-rellax-speed='-1' style={{ zIndex: '-1000', marginLeft: '10vw', minHeight: '130px', maxWidth: '130px', borderRadius: '20%', backgroundColor: 'rgba(255, 165, 0, 0.3)', transform: 'rotate(45deg)' }}></div>
        <div className="bg-elements-5 rellax  mr-auto" data-rellax-speed='-4' style={{ zIndex: '-1000', marginLeft: '90vw', minHeight: '110px', maxWidth: '110px', borderRadius: '20%', backgroundColor: 'rgba(255, 165, 0, 0.3)', transform: 'rotate(45deg)' }}></div>
        <div className="bg-elements-5 rellax  mr-auto" data-rellax-speed='-1' style={{ zIndex: '-1000', marginLeft: '90vw', minHeight: '110px', maxWidth: '110px', borderRadius: '20%', backgroundColor: 'rgba(255, 165, 0, 0.3)', transform: 'rotate(45deg)' }}></div>
      </div>


      <div className="main">
        <div className="contact-content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto card-contact card-raised card" style={{ maxWidth: "90vw" }} md="5">
                <div>
                  <h2 className="title" style={{ fontFamily: "Quintessential", textAlign: "center" }}>Send us a message</h2>
                  {/* <hr style={{width:"10vw",border:"1px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr> */}
                </div>
                <p className="description" style={{ color: "black", fontWeight: 400 }}>
                  You can contact us with anything related to our Services.
                    We'll get in touch with you as soon as possible. <br></br>
                  <br></br>
                </p>
                <Form id="contact-form" role="form" onSubmit={ (e) => formSubmit(e)}>
                  <label>Your name</label>
                  <InputGroup
                    className={nameFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={e => setName(e.target.value)}
                      aria-label="Your Name..."
                      autoComplete="name"
                      placeholder="Your Name..."
                      type="text"
                      onFocus={() => setNameFocus(true)}
                      onBlur={() => setNameFocus(false)}
                    ></Input>
                  </InputGroup>
                  <label>Email address</label>
                  <InputGroup
                    x className={emailFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={e => setEmail(e.target.value)}
                      aria-label="Email Here..."
                      autoComplete="email"
                      placeholder="Email Here..."
                      type="email"
                      onFocus={() => setEmailFocus(true)}
                      onBlur={() => setEmailFocus(false)}
                    ></Input>
                  </InputGroup>
                  <label>Phone</label>
                  <InputGroup
                    className={numberFocus ? "input-group-focus" : ""}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons tech_mobile"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={e => setNumber(e.target.value)}
                      autoComplete="number"
                      placeholder="Number Here..."
                      type="text"
                      onFocus={() => setNumberFocus(true)}
                      onBlur={() => setNumberFocus(false)}
                    ></Input>
                  </InputGroup>
                  <FormGroup>
                    <label>Your message</label>
                    <Input
                    onChange={e => setMessage(e.target.value)}
                      id="message"
                      name="message"
                      rows="6"
                      type="textarea"
                    ></Input>
                  </FormGroup>
                  <div className="submit text-center">
                    <Button
                      className="btn-raised btn"
                      color="info"
                    >
                      { buttonText }
                      </Button>
                  </div>
                </Form>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <div className="info info-horizontal mt-5">
                  <div className="icon icon-info">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{ fontWeight: 400, textAlign: "center" }}>Find us at the office</h4>
                    {/* <hr style={{width:"5vw",border:"1px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr> */}
                    <p style={{ fontWeight: 400, color: "black", textAlign: "center" }}><a href="https://www.google.com/maps?ll=28.650656,77.186662&z=19&t=m&hl=en&gl=IN&mapclient=embed&cid=13449040920796025209">
                      4375/58 Basement,Regarpur <br></br>
                        Karol Bagh, New Delhi-110005 <br></br>
                        India
                        </a>
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{ fontWeight: 400, textAlign: "center" }}>Give us a ring</h4>
                    {/* <hr style={{width:"5vw",border:"1px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr> */}

                    <p style={{ fontWeight: 400, color: "black", textAlign: "center" }}>
                      Sunil Kumar <br></br>
                      <a href="tel:+919899116666">+91 9899116666</a> <br></br>
                      <a href="tel:+919899116777">+91 9899116777</a> <br></br>
                    </p>
                  </div>
                </div>

                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons shopping_credit-card"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{ fontWeight: 400, textAlign: "center" }}>Email us at </h4>
                    {/* <hr style={{width:"5vw",border:"1px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr> */}

                    <p style={{ fontWeight: 400, color: "black", textAlign: "center" }}>
                      <a href="mailto:sunil@mahagauri.co.in">sunil@mahagauri.co.in</a> <br></br>
                    </p>
                  </div>
                </div>


                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-1_send"></i>
                  </div>
                  <div className="description" style={{ textAlign: "center" }}>
                    <h4 className="info-title" style={{ fontWeight: 400, textAlign: "center" }}>Connect with us at </h4>
                    <div className="buttons">
                      <Button className="btn-icon btn-round mt-2 mr-1" color="twitter">
                        <i className="fab fa-twitter"></i>
                      </Button>
                      <Button className="btn-icon btn-round mt-2 mr-1" color="facebook">
                        <i className="fab fa-facebook"></i>
                      </Button><Button className="btn-icon btn-round mt-2 mr-1" color="google">
                        <i className="fab fa-google-plus"></i>
                      </Button>
                      <Button className="btn-icon btn-round mt-2 mr-1" color="linkedin">
                        <i className="fab fa-linkedin"></i>
                      </Button>
                    </div>
                  </div>
                </div>


              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Row style={{ justifyContent: "center" }}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d437.6629182135658!2d77.1866517!3d28.6506345!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d039206be5827%3A0xbaa497761dbd5179!2sMaha%20Gauri%20Enterprises!5e0!3m2!1sen!2sin!4v1592807400219!5m2!1sen!2sin" width="80%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0">
        </iframe>
      </Row>
      <br />
      <Footer />
      {/* </div> */}
    </React.Fragment>
  );
}

export default ContactUs;
