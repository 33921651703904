import React from 'react'
import Rellax from "rellax";


import {
    Container,
    Row,
    Col
  } from "reactstrap";
  
  import AOS from "aos"
import "aos/dist/aos.css"
import MyNav from '../components/Navbars/FixedTransparentNavbar';
import AboutUsHeader from "components/Headers/AboutUsHeader";
import Footer from '../components/Footers/FooterDefault';
import Features from '../components/Features';

import '../assets/css/custom.css'

export default function AboutPage() {

  React.useEffect(() => {
    AOS.init({duration:"1000"});
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (window.innerWidth >= 801) {
      setTimeout(function() {
       new Rellax(".rellax", {
          center: false
        });
      }, 100);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

    return (
        <React.Fragment>
            <MyNav activelink='About Us'/>
            <AboutUsHeader />

      <div className='bg-elements ' style={{height:0,width:'100%',marginTop:"30vh", position:'absolute',zIndex:'-1000'}}>
       <div className="bg-elements-1 rellax  ml-5 mr-auto"  data-rellax-speed="-5" style={{minHeight:'100px',maxWidth:'100px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div> 
       <div className="bg-elements-2 rellax mr-5 ml-auto"  data-rellax-speed='-2' style={{ minHeight:'150px',maxWidth:' 150px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-3 rellax  mr-auto"  data-rellax-speed='-3' style={{ zIndex:'-1000' ,marginLeft:'40vw',minHeight:'50px',maxWidth:'50px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-4 rellax  mr-auto"  data-rellax-speed='-1' style={{ zIndex:'-1000' ,marginLeft:'70vw',minHeight:'130px',maxWidth:'130px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-5 rellax  mr-auto"  data-rellax-speed='-4' style={{ zIndex:'-1000' ,marginLeft:'30vw',minHeight:'110px',maxWidth:'110px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-5 rellax  mr-auto"  data-rellax-speed='-2' style={{ zIndex:'-1000' ,marginLeft:'50vw',minHeight:'110px',maxWidth:'110px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-5 rellax  mr-auto"  data-rellax-speed='-4' style={{ zIndex:'-1000' ,marginLeft:'20vw',minHeight:'110px',maxWidth:'110px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       </div> 

<div className="about-main">
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 data-aos="zoom-out-up" style={{fontFamily:"Quintessential"}} className="title">Who we are?</h2>
<Col className="ml-auto mr-auto text-center" md="12">
                <h5 data-aos="zoom-out-up"  className="description" style={{fontWeight:400,color:"black"}}>
                    
“Once our Customer , always our Customer" <br/><br/>
</h5>
<div data-aos="zoom-in-up">
  <hr style={{width:"10vw",border:"3px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr>
</div>
<br/>
</Col>
             <Col className="ml-auto mr-auto text-center" md="12">
                 <Row>
                <h5 data-aos="zoom-in-down"  className="description" style={{fontWeight:400,color:"black"}}>
                Established in 2016, Maha Gauri Enterprises led by Mr. Sunil Kumar is into import and export of variety of food products.

We import from multiple countries including UK and Thailand and are soon planning to expand our network to US and Poland as well. 

We put extreme emphasis on the satisfaction of our customers with regards to our products and services. 
We make huge and unprecedented efforts to make sure that our customers are happy with a buying experience 
with on all aspects .Many of our Buyers have been with us since our inception & are Extremely satisfied 
with us in all aspects & it is by their Support ,we have Established ourselves as one of the Recognized & Reputed Organizations in the Domestic as well as International markets . 
                 </h5>
                 </Row>
              </Col>
              </Col>
            </Row>
            {/* <div className="separator separator-info"></div> */}
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left image-aboutPage"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/mge6.jpg") + ")"
                    }}
                  >
                  </div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/mge4.jpg") + ")"
                    }}
                  ></div>
                </Col>
              </Row>
              <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 data-aos="fade-right" style={{fontFamily:"Quintessential"}}  className="title">Why us?</h2>
                <h5 data-aos="fade-left"   className="description" style={{fontWeight:400,color:"black"}}>
We put extreme emphasis on the satisfaction of our customers with regards to our products and services. 
We make huge and unprecedented efforts to make sure that our customers are happy with a buying experience 
with on all aspects .Many of our Buyers have been with us since our inception & are Extremely satisfied 
with us in all aspects & it is by their Support ,we have Established ourselves as one of the Recognized & Reputed Organizations in the Domestic as well as International markets . 
                </h5>
            </Col>
        </Row>
    </div>
</Container>
<div data-aos="zoom-in-up">
  <hr style={{width:"10vw",border:"3px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr>
</div>
          <Features />
            <Footer />
            </div>
        </React.Fragment>
    )
}
