import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
// core components

function Cards() {
  React.useEffect(()=>{
      AOS.init()
  },[]);
  return (
        <Container>
          <div data-aos="fade-left" className="title" style={{textAlign: "center"}}>
            <h2 style={{fontFamily:"Quintessential"}}>Here are few Brands we deal with</h2>
          </div>
          <Row>

          <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/Cadbury.svg")}
               ></img></Link>
             </Col>
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/nutella.svg")}
               ></img></Link>
             </Col>          
             {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/wrigleys.svg")}
               ></img></Link>
             </Col>        */}
                {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/logos/image2vector(3).svg")}
               ></img></Link>
             </Col>        */}
                {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/logos/image2vector(4).svg")}
               ></img></Link>
             </Col>   */}
                     <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/nestle.svg")}
               ></img></Link>
             </Col>       
                {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/logos/image2vector(6).svg")}
               ></img></Link>
             </Col>           */}
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/Tate&Lyle.svg")}
               ></img></Link>
             </Col>
                              
                {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/logos/image2vector(12).svg")}
               ></img></Link>
             </Col>     */}
                   {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/logos/image2vector(13).svg")}
               ></img></Link>
             </Col>      */}
                  <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/doritos.svg")}
               ></img></Link>
             </Col>
                       <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/heinz-1869.svg")}
               ></img></Link>
             </Col>      
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/monster.svg")}
               ></img></Link>
             </Col>
                 <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/pringles-2.svg")}
               ></img></Link>
             </Col>          <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/lipton.svg")}
               ></img></Link>
             </Col>          
             
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
                 alt="..."
                 className="rounded img-thumbnail" style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/knorr.svg")}
               ></img>
               </Link>
             </Col>
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/wrigley-s.svg")}
               ></img></Link>
             </Col>

             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/tabasco.svg")}
               ></img></Link>
             </Col>
                  <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/lavazza-7.svg")}
               ></img></Link>
             </Col>    
                <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/coca-cola.svg")}
               ></img></Link>
             </Col>     
             
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/Nescafe.svg")}
               ></img></Link>
             </Col>    
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/kellogg-s-red.svg")}
               ></img></Link>
             </Col>  
                   <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/twining.svg")}
               ></img></Link>
             </Col>

                <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/alpen-gold.svg")}
               ></img></Link>
             </Col>
             {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/nature-valley.svg")}
               ></img></Link>
             </Col>     
                  <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/tabasco.svg")}
               ></img></Link>
             </Col>   
             <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/farr.svg")}
               ></img></Link>
             </Col>    */}











              {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2"> */}
{/*              
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/image2vector(21).svg")}
               ></img></Link>
             </Col>   */}
             {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/tabasco.svg")}
               ></img></Link>
             </Col>           */}
             {/* <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/logos/image2vector(22).svg")}
               ></img></Link>
             </Col>          <Col data-aos="flip-left" className='p-3' xs="4" sm="3" md="2">
             
             <Link>
               <img 
               width='100%'
    
                 alt="..."
                 className="rounded img-thumbnail " style={{border:0,boxShadow:' 0px 10px 25px 0px rgba(0, 0, 0, 0.3)'}}
                 src={require("assets/img/logos/image2vector(23).svg")}
               ></img></Link>
             </Col> */}
            {/* <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card2.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card3.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card4.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card5.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card6.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card7.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card8.png")}
                  ></img>
                </div>
              </Card>
            </Col>
            
            <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card9.png")}
                  ></img>
                </div>
              </Card>
            </Col> 
            <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card10.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card11.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card12.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card13.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card14.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card15.jpg")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card16.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card17.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card18.jpg")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card19.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card20.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card21.jpeg")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card22.png")}
                  ></img>
                </div>
              </Card>
            </Col>             <Col lg="3" md="3">
              <Card className="card-blog card-plain">
                <div className="card-image">
                  <img
                    alt="..."
                    className="img rounded img-raised"
                    src={require("assets/img/card23.png")}
                  ></img>
                </div>
              </Card>
              </Col> */}
            </Row>
        </Container>
  );
}

export default Cards;
