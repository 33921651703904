import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import aboutHeaderImg from "assets/img/aboutusbanner.jpg";
// core components
import AOS from "aos";
import "aos/dist/aos.css";

function AboutUsHeader() {
  React.useEffect(()=>{
    AOS.init();
  },[]);
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            background:"linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.6)),"+ "url(" + aboutHeaderImg + ")"
          ,backgroundSize:'cover',
          backgroundPosition:'center'
}}
          ref={pageHeader}
        ></div>
        <div className="content-center">
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h1 data-aos="fade-right" data-aos-duration="2500" style={{fontFamily:"Quintessential"}} className="title">About Us</h1>
              <h4 data-aos="fade-left" style={{fontFamily:"Quintessential"}} data-aos-duration="2500">
              Find out more about how we work!
              </h4>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
