import React from "react";
import LandingPageHeader from '../components/Headers/LandingPageHeader';
import MyNav from '../components/Navbars/FixedTransparentNavbar';
import Cards1 from "../components/Cards1"
import Cards from "../components/Cards"
import Rellax from "rellax";
import AOS from 'aos';
import Footer from "../components/Footers/FooterDefault"
import {Col,Row,Container} from "reactstrap";
// const items = [
//   {
//     src: "url(" + require("assets/img/mge2.jpg") + ")",
//     content: (
//       <Row>
//         <Col className="ml-auto mr-auto text-center" md="8">
//           <h1 className="title">Welcome to MahaGauri Enterprises</h1>
//           <h4 className="description" style={{fontWeight:500}}>
//             MahaGauri Enterprises is incorporated for imports of variety of food 
//             products. Our importing line includes products like, 
//             Pastas, Pasta Sauces, Olives, Olive Oils, Coffee
//             , Vinegars from all across the Globe.
//           </h4>
//           <br></br>
//           <h5>Connect with us on:</h5>
//           <div className="buttons">
//             <Button
//               className="btn-icon btn-neutral btn-round mt-2 mr-1"
//               color="danger"
//               href="#pablo"
//               onClick={e => e.preventDefault()}
//             >
//               <i className="fab fa-twitter"></i>
//             </Button>
//             <Button
//               className="btn-icon btn-neutral btn-round mt-2 mr-1"
//               color="danger"
//               href="#pablo"
//               onClick={e => e.preventDefault()}
//             >
//               <i className="fab fa-facebook-square"></i>
//             </Button>
//             <Button
//               className="btn-icon btn-neutral btn-round mt-2 mr-1"
//               color="danger"
//               href="#pablo"
//               onClick={e => e.preventDefault()}
//             >
//               <i className="fab fa-google-plus"></i>
//             </Button>
//             <Button
//               className="btn-icon btn-neutral btn-round mt-2"
//               color="danger"
//               href="#pablo"
//               onClick={e => e.preventDefault()}
//             >
//               <i className="fab fa-instagram"></i>
//             </Button>
//           </div>
//         </Col>
//       </Row>
//     ),
//     altText: "",
//     caption: ""
// }
// ,
//   {
//     src: "url(" + require("assets/img/mge4.jpg") + ")",
//     content: (
//       <Row>
//         <Col className="ml-auto mr-auto text-center" md="8">
//           <h1 className="title">Fresh new Items from all around the Globe</h1>
//           <h5 className="title">You Name it. We Have it!</h5>
//           <Button
//               color="neutral"
//               href="#pablo"
//               onClick={e => e.preventDefault()}
//               size="lg">
//               Products
//             </Button>
//         </Col>
//       </Row>
//     ),
//     altText: "",
//     caption: ""
//   },
//   {
//     src: "url(" + require("assets/img/mge5.jpg") + ")",
//     content: (
//       <Row>
//         <Col className="ml-auto mr-auto text-center" md="8">
//           <h1 className="title">Client Satisfaction</h1>
//           <h4 className="description">
//           "Once our Customer , always our Customer"
//           <br></br>
//           If Our Clients Succeed 
//           & are satisfied,we shall be so!
//           </h4>
//           <br></br>
//           <div className="buttons">
//             <Button
//               className="btn-neutral mr-1"
//               color="info"
//               href="#pablo"
//               onClick={e => e.preventDefault()}
//               size="lg"
//             >
//               <i className="now-ui-icons files_single-copy-04"></i> About
//             </Button>
//             <Button
//               color="info"
//               href="#pablo"
//               onClick={e => e.preventDefault()}
//               size="lg"
//             >
//               <i className="now-ui-icons arrows-1_share-66"></i> Contact
//             </Button>
//           </div>
//         </Col>
//       </Row>
//     ),
//     altText: "",
//     caption: ""
//   }
// ];

function LandingPage(props) {


  React.useEffect(() => {
    AOS.init({duration:1000});
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (window.innerWidth >= 801) {
      setTimeout(function() {
        new Rellax(".rellax", {
          center: false
        });
      }, 100);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  // // header 3 carousel states and functions
  // const [activeIndex, setActiveIndex] = React.useState(0);
  // const [animating, setAnimating] = React.useState(false);
  // const onExiting = () => {
  //   setAnimating(true);
  // };
  // const onExited = () => {
  //   setAnimating(false);
  // };
  // const next = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
  //   setActiveIndex(nextIndex);
  // };
  // const previous = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
  //   setActiveIndex(nextIndex);
  // };
  // const goToIndex = newIndex => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // };

  return (

    <React.Fragment>
      <MyNav activelink="Home" />
      {/* <NavBar /> */}
      {/* <MyNav /> */}
      
      <LandingPageHeader />

      <div className='bg-elements ' style={{height:0,width:'100%',marginTop:"30vh", position:'absolute',zIndex:'-1000'}}>
       <div className="bg-elements-1 rellax  ml-5 mr-auto"  data-rellax-speed="1" style={{minHeight:'100px',maxWidth:'100px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div> 
       <div className="bg-elements-2 rellax mr-5 ml-auto"  data-rellax-speed='0' style={{ minHeight:'150px',maxWidth:' 150px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-3 rellax  mr-auto"  data-rellax-speed='-2' style={{ zIndex:'-1000' ,marginLeft:'40vw',minHeight:'50px',maxWidth:'50px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-4 rellax  mr-auto"  data-rellax-speed='-3' style={{ zIndex:'-1000' ,marginLeft:'70vw',minHeight:'130px',maxWidth:'130px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-5 rellax  mr-auto"  data-rellax-speed='-1' style={{ zIndex:'-1000' ,marginLeft:'20vw',minHeight:'110px',maxWidth:'110px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       </div> 

<Container>
  <Row style={{justifyContent:"center"}}>
       <h2 data-aos="zoom-out-up" style={{fontFamily:"Quintessential"}} className="title">Who we are?</h2>
       </Row>
       <Col className="ml-auto mr-auto text-center" md="12">
                 <Row>
                <h5 data-aos="zoom-in-down"  className="description" style={{fontWeight:400,color:"black"}}>
                Established in 2016, Maha Gauri Enterprises led by Mr. Sunil Kumar is into import and export of variety of food products.
We put extreme emphasis on the satisfaction of our customers with regards to our products and services. 
We make huge and unprecedented efforts to make sure that our customers are happy with a buying experience 
with on all aspects.Many of our Buyers have been with us since our inception & are Extremely satisfied 
with us in all aspects & it is by their Support ,we have Established ourselves as one of the Recognized & Reputed Organizations in the Domestic as well as International markets . 
                  </h5>
{/* <a href="/products"> <h3 style={{color:"orange",textAlign:"center"}}>Explore All Products</h3> </a> */}

                 </Row>
                 <a href="/about-us"><h4 style={{textAlign:"center",marginTop:"-2px"}}>Know more</h4></a>

              </Col>

              </Container>
      {/* <div className="header-3">
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {items.map(item => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <div className="page-header header-filter">
                    <div
                      className="page-header-image"
                      style={{
                        backgroundImage: item.src
                      }}
                    ></div>
                    <div className="content">
                      <Container className="text-left">
                        {item.content}
                      </Container>
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div> */}

        
        <div className="landing-main">
      <br />
      <br />
      <div data-aos="zoom-in-up">
  <hr style={{width:"10vw",border:"3px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr>
</div>
      <br />
      <Cards />

      <br />
      <br />
      <a href="/products"> <h3 style={{color:"orange",textAlign:"center"}}>Explore All Products</h3> </a>
      <hr style={{width:"10vw",border:"3px solid rgb(255, 153, 255)",borderRadius:"7px"}}></hr>
      <br />
      <Cards1 />

      <br />
      <br />
      
      <br />
      </div>
      <Footer />
    </React.Fragment>

  );
}

export default LandingPage;