import React from 'react'

import MyNav from '../components/Navbars/FixedTransparentNavbar';
import ProductHeader from "components/Headers/ProductPageHeader";
import Footer from '../components/Footers/FooterDefault';
import Rellax from "rellax";
// import Cards from '../components/Cards';
import ProductItems from '../components/productItems';

export default function Products() {


    React.useEffect(() => {

        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");

    if (window.innerWidth >= 801) {
        setTimeout(function() {
          new Rellax(".rellax", {
            center: false
          });
        }, 100);
        new Rellax(".rellax-header");
        new Rellax(".rellax-text");
      }
        return function cleanup() {
          document.body.classList.remove("sidebar-collapse");
        };
      }, []);
    


    return (
        <React.Fragment>
            <MyNav activelink="Products"/>
            <ProductHeader />

      <div className='bg-elements ' style={{height:0,width:'100%',marginTop:"30vh", position:'absolute',zIndex:'-1000'}}>
       <div className="bg-elements-1 rellax  ml-5 mr-auto"  data-rellax-speed="-5" style={{minHeight:'100px',maxWidth:'100px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div> 
       <div className="bg-elements-2 rellax mr-5 ml-auto"  data-rellax-speed='-2' style={{ minHeight:'150px',maxWidth:' 150px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-3 rellax  mr-auto"  data-rellax-speed='-3' style={{ zIndex:'-1000' ,marginLeft:'40vw',minHeight:'50px',maxWidth:'50px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-4 rellax  mr-auto"  data-rellax-speed='-1' style={{ zIndex:'-1000' ,marginLeft:'50vw',minHeight:'130px',maxWidth:'130px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-5 rellax  mr-auto"  data-rellax-speed='-4' style={{ zIndex:'-1000' ,marginLeft:'60vw',minHeight:'110px',maxWidth:'110px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-5 rellax  mr-auto"  data-rellax-speed='-4' style={{ zIndex:'-1000' ,marginLeft:'70vw',minHeight:'110px',maxWidth:'110px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       <div className="bg-elements-5 rellax  mr-auto"  data-rellax-speed='-4' style={{ zIndex:'-1000' ,marginLeft:'20vw',minHeight:'110px',maxWidth:'110px',borderRadius:'20%', backgroundColor:'rgba(255, 165, 0, 0.3)',transform:'rotate(45deg)'}}></div>
       </div> 


            <div className="about-main">
            <br/>
            <br/>
            <ProductItems/>
            </div>
                <Footer />
            {/* <Cards /> */}
        </React.Fragment>
    )
}
