import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages

import ProductsPage from "./views/ProductsPage";
import AboutPage from "./views/AboutPage";
import LandingPage from "./views/LandingPage.js";
import ContactPage from "./views/ContactPage.js"
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
    <Route
        path="/home"  
        render={props => <LandingPage {...props} />}
      />


<Route
        path="/about-us"  
        render={props => <AboutPage {...props} />}
      />



    <Route
        path="/contact-us" 
        render={props => <ContactPage {...props} />}
      />

<Route
        path="/products" 
        render={props => <ProductsPage {...props} />}
      />

      <Redirect to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
