import React from 'react'

import {
    Card,
    Container,
    Row,
    Col
  } from "reactstrap";
  
import AOS from "aos";
import "aos/dist/aos.css";

export default function Cards1() {

  React.useEffect(()=>{
    AOS.init();
  },[]);

    return (
        <>
                 {/* <Container fluid>
          <Row>
            <Col className="px-0" md="6">
              <Card
                className="card-fashion card-background"
                style={{
                  backgroundImage: "url(" + require("assets/img/card11.jpg") + ")"
                }}
              >
                <CardBody>
                  <CardTitle className="text-left" tag="div">
                    <h2>

                    </h2>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col className="px-0" md="6">
              <div className="card-container">
                <Card className="card-fashion">
                  <CardTitle tag="h4">
                    We take utmost care of the quality of our products and 
                    never disappoint our customers.
                  </CardTitle>
                  <CardBody>
                    </CardBody>
                </Card>
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage: "url(" + require("assets/img/card12.jpg") + ")"
                  }}
                ></Card>
              </div>
            </Col>
            <Col className="px-0" md="6">
              <div className="card-container">
              <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage: "url(" + require("assets/img/card13.jpg") + ")"
                  }}
                ></Card>
                <Card className="card-fashion">
                  <CardTitle tag="h4">
                  Many of our Buyers have been with us 
                  since our inception & are Extremely satisfied with us in all aspects                  </CardTitle>
                  <CardBody>
                  </CardBody>
                </Card>
              </div>
            </Col>
            <Col className="px-0" md="6">
              <Card
                className="card-fashion card-background"
                style={{
                  backgroundImage: "url(" + require("assets/img/card14.png") + ")"
                }}
              >
                <CardBody>
                  <CardTitle className="text-left" tag="div">
                    <h2>
                    </h2>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
 
          </Row>
        </Container> */}
         <Container>
            <Row  data-aos="fade-left" data-aos-duration="1000">
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{fontFamily:"Quintessential"}}>Working with us, a pleasure!</h2>
                <h4 style={{color:"black"}} className="description">
                Many of our Buyers have been with us 
                  since our inception & are Extremely satisfied with us in all aspects  
                </h4>
              </Col>
            </Row>
            <Row>
            </Row>
            <Row style={{paddingTop:"20px"}}>
              <Col md="4">
                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.5)),"+ "url(" + require("assets/img/cardImg1.jpg") + ")",
                  }}
                >
                  <div  data-aos="zoom-in" className="info info-hover">
                    <div className="icon icon-white">
                      <i className="now-ui-icons objects_diamond"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Quality</h4>
                      <p className="landingFeatures">
                      We take utmost care of the quality of our products and 
                    never ever disappoint our customers.
                      </p>
                      {/* <a
                        className="ml-3"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        Find more...
                      </a> */}
                    </div>
                  </div>
                </Card>
              </Col>

              <Col md="4">
                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.5)),"+ "url(" + require("assets/img/cardImg2.jpg") + ")"

                  }}
                >
                  <div  data-aos="zoom-in" className="info info-hover">
                    <div className="icon icon-white">
                      <i className="now-ui-icons emoticons_satisfied"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Trust</h4>
                      <p  className="landingFeatures">
                      We only trade products of repute which have demand for in the Indian as well as International markets.
                      </p>
                      {/* <a
                        className="ml-3"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        Find more...
                      </a> */}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  className="card-background card-raised"
                  data-background-color=""
                  style={{
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.5)),"+ "url(" + require("assets/img/cardImg3.jpeg") + ")"
                  }}
                >
                  <div  data-aos="zoom-in" className="info info-hover">
                    <div className="icon icon-white">
                      <i className="now-ui-icons business_bulb-63" ></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Commitment</h4>
                      <p  className="landingFeatures">
                      The company has grown leaps and bounds in the past 5 years 
                      and is continuously on the lookout for new business opportunities.
                      </p>
                      {/* <a
                        className="ml-3"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        Find more...
                      </a> */}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        
        </>
    )
}
