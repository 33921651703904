import React from 'react'
import {
    Card,
    Row,
    Col,
  } from "reactstrap";
  import  '../assets/css/custom.css';

export default function productItems() {
    return (
        <div>
               <h2  style={{textAlign: "center",fontFamily:"Satisfy",fontWeight:'10'}} className="title">Here are few items from our wide range of products</h2>
            <Row style={{paddingLeft:'5vw',paddingRight:'5vw'}}>
            <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product1.jpg")}
                            ></img>
                          </a>
                        </div>
 
                        {/* <br/> */}
                        <br/>
                        <p className="card-description">
                        Nescafe Coffees
                      </p>
                      </Card>
                    </Col>    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product2.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Namjai Curry Paste
                      </p>

                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product3.jpg")}
                            ></img>
                          </a>
                        </div>
  
                        <br/>
                        <p className="card-description">
                        Rice Stick Noodles
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product4.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        JB Choco Coin
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product5.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Nando's Sauces
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product6.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Skittles
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product7.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Old Jamaica Ginger Beer 
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product8.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Sweet Chilli Sauce
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product9.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Glutenous Rice Flour
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product10.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Cheerios
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product11.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Weetabix
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product12.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Kellog's Variety Pack
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product13.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Tata Lyle Cane Sugar
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product14.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Kellogs Frosties
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product15.png")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Queen Candy Sweet Rainbow
                      </p>
                      </Card>
                    </Col>           
                        <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product16.png")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Chinese Sesame Oil
                      </p>
                      </Card>
                    </Col>      
                             <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product17.png")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                       Heinz Sauces
                      </p>
                      </Card>
                    </Col>     
                              <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product18.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Farleys Rusks
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product19.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Hellmann's Mayonese
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product20.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Tabasco Sauce
                      </p>
                      </Card>
                    </Col>               <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product21.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Nature Valley Protein Bar
                      </p>
                      </Card>
                    </Col>  
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product22.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Nutella B-Ready
                      </p>
                      </Card>
                    </Col>              <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product23.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Cadbury Cocoa Powder
                      </p>
                      </Card>
                    </Col>  
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product24.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Kellog's Bran Flakes
                      </p>
                      </Card>
                    </Col>  
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product25.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Twining's Green Tea
                      </p>
                      </Card>
                    </Col>  
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product26.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Pringles
                      </p>
                      </Card>
                    </Col>      
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product27.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Hubba Bubba Bubble Gum
                      </p>
                      </Card>
                    </Col>    
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product28.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Ribenas Black Current Syrup
                      </p>
                      </Card>
                    </Col>    
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product29.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Nestle Cerelac
                      </p>
                      </Card>
                    </Col>    
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product30.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Lucozade Energy Drink
                      </p>
                      </Card>
                    </Col>    
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product31.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Lipton Ice Tea
                      </p>
                      </Card>
                    </Col>    
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product32.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Jasmine Green Tea
                      </p>
                      </Card>
                    </Col>    
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product33.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Shitake Mushrooms
                      </p>
                      </Card>
                    </Col>    
                    
                    <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img className="productImg"
                              alt="..." 
                              src={require("assets/img/products/product34.jpg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Sriracha Chilli Sauce
                      </p>
                      </Card>
                    </Col>           
                     {/* <Col lg="2" md="2" sm="4" xs="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img 
                              alt="..." 
                              src={require("assets/img/products/product22.jpeg")}
                            ></img>
                          </a>
                        </div>
                             <br/>
                        <p className="card-description">
                        Red Curry Paste
                      </p>
                      </Card>
                    </Col>                 */}
                </Row>
         
        </div>
    )
}
