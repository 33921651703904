/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

import Logo from '../../assets/img/logoA.png'

function FixedTransparentNavbar(props) {
  // const [navbarOpen3, setNavbarOpen3] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(()=>{
if(props.activelink){
  let x= document.getElementsByClassName('nav-item');
  let z=Array.from(x);
  console.log(z);
  z.forEach((item)=>{
    console.log(item.firstChild.innerHTML)
    if(item.firstChild.innerHTML==props.activelink){
      item.classList.add('active');
    }
  })
}
  })
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="navbar-absolute navbar-transparent" expand="lg">
        <Container>
          <div className="navbar-translate">
            {/* <NavbarBrand to="/home" tag={Link} id="navbar-brand">
                MahaGauri Enterprises
            </NavbarBrand> */}
            <img src={Logo} width='280px' alt='website logo' />
                        <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
                <Nav  className="ml-auto borderYtoX" navbar>
                  <NavItem>
                    <Link style={{fontSize:"1.0542em",color:"rgb(201,250, 255)"}} to='/home'  onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }} className='nav-link'>
                      Home
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link style={{fontSize:"1.0542em",color:"rgb(201,250, 255)"}} to='/about-us'   onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }} className='nav-link'>
                      About Us
                    </Link>
                  </NavItem>

                  <NavItem>
                    <Link style={{fontSize:"1.0542em",color:"rgb(201,250, 255)"}} to="/products"  onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}  className='nav-link'>
                      Products
                    </Link>
                  </NavItem>

                  <NavItem>
                    <Link style={{fontSize:"1.0542em",color:"rgb(201,250, 255)"}} to="/contact-us"   onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }} className='nav-link' >
                      Contact Us
                    </Link>
                  </NavItem>
                  
                </Nav>
              </Collapse>
            </Container>
      </Navbar>
    </>
  );
}

export default FixedTransparentNavbar;
